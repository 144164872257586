@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Space+Grotesk:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap');

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.2rem;
  }
  75% {
    margin-left: -0.2rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.chakra-pin-input[aria-invalid='true'] {
  animation: shake 0.2s ease-in-out 0s 2 !important;
  border-color: 0 0 0.2rem #f86078 !important;
}

* {
  ::-webkit-scrollbar-track {
    background-color: background-color;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 24px;
    background-color: background-color;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background-color: background-color;
  }

  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  button:focus,
  select:focus,
  textarea:focus,
  input:focus {
    font-size: initial;
  }
}

.hidden-audio {
  audio {
    display: none;
  }
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.timeline-select-scroll {
  scroll-behavior: auto !important;
}

.timeline-select-scroll::-webkit-scrollbar-track {
  background-color: #403c5d;
}

.timeline-select-scroll::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  border-radius: 4px;
}

.timeline-select-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #47e7a2;
}

.select {
  option {
    background-color: #322f4d !important;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spacing {
  margin-right: 4px;
  padding: 12px 0;

  &:last-child {
    margin-right: 0;
  }
}

.accordion-feedback {
  width: 100%;
  overflow: hidden;

  .accordion-feedback-header {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: background-color 0.2s ease;
    padding: 18px 5px;
    user-select: none;
    outline: none;
  }

  .accordion-feedback-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .accordion-feedback-content {
    max-height: 0;
    overflow: hidden;
    padding: 0 24px 0px;
    transition: max-height 0.2s ease-out;
    border-bottom: 1px solid #403c5d;

    &:last-of-type {
      border: 0;
    }
  }

  .accordion-feedback-content.active {
    animation: slideDown 0.2s ease-out forwards;
  }
  .accordion-feedback-content.inactive {
    animation: slideUp 0.2s ease-out forwards;
  }

  .accordion-feedback-content p {
    padding-bottom: 20px;
  }

  ol {
    margin-left: 20px !important;
  }
}

.accordion {
  width: 100%;
  overflow: hidden;

  .accordion-header {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: background-color 0.2s ease;
    padding: 32px 0;
    user-select: none;
    outline: none;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-bottom: 1px solid #403c5d;

    &:last-of-type {
      border: 0;
    }
  }

  .accordion-content.active {
    animation: slideDown 0.2s ease-out forwards;
  }
  .accordion-content.inactive {
    animation: slideUp 0.2s ease-out forwards;
  }

  .accordion-content p {
    padding-bottom: 20px;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px; /* Altere este valor conforme necessário */
  }
}
@keyframes slideUp {
  0% {
    max-height: 500px; /* O mesmo valor usado em slideDown */
  }
  100% {
    max-height: 0;
  }
}

.title-animation {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  gap: 56px;
  text-align: center;

  .block-animation {
    animation: marquee 40s linear infinite;
    background: linear-gradient(90deg, #58537a 0%, #8d88b5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.svg-stroke {
  > svg {
    stroke: #4df9ae;
    color: #4df9ae;
  }
}

.svg-fill {
  > svg {
    fill: #4df9ae;
  }
}

.syntax-format {
  hr {
    border-color: #58537a79;
    margin: 12px 0;
    display: none;
  }
  .code-markdown {
    background-color: #222034;
    color: #dbd3f0;
    border-radius: 4px;
    padding: 0.2em 0.4em;
    font-family: 'Courier New', Courier, monospace;
  }

  .pre-markdown {
    background-color: #222034;
    color: #dbd3f0;
    border-radius: 4px;
    padding: 1em;
    margin: 16px 0;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;
  }

  ol,
  ul {
    margin: 1rem 0;
    margin-left: 1.5em;
    padding-left: 0.5em;
    font-size: 0.9rem;

    > ol,
    ul {
      margin: 0.5rem 0.8rem;
    }

    li {
      margin: 0.1em 0;
      margin-left: 0.1em;
    }
  }
}

.menu-sidebar {
  transition: width 0.5s ease-in-out;
}

.menu-sidebar-item {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.menu-sidebar.active .menu-sidebar-item {
  opacity: 1;
}

.custom-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: flex;
  justify-content: center;
  width: 100%;

  li {
    margin: 0 3px !important;

    button:before {
      font-size: 8px !important;
      color: #403c5d !important; // Cor das dots ativas
      opacity: 1 !important;
    }

    &.slick-active {
      button:before {
        color: #4df9ae !important; // Cor das dots
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cursor {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: #dbd3f0;
  animation: blink 1s infinite;
}

.glow {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  position: absolute;
}

.glow::before,
.glow::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  filter: blur(45px);
  transform: translateZ(0);
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.glow::before {
  background: linear-gradient(
    to bottom right,
    rgba(1, 65, 255, 0),
    rgba(1, 65, 255, 0),
    rgba(208, 255, 1, 0.39)
  );
  animation-name: fadeInOut;
}

.glow::after {
  background: radial-gradient(rgba(1, 255, 65, 0.438), rgba(1, 247, 255, 0.1));
  animation-name: fadeOutIn;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes fadeOutIn {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-track {
  background: none !important;
}

.react-horizontal-scrolling-menu--scroll-container.no-scroll-brief {
  grid-column-gap: 0 !important;
}

.react-horizontal-scrolling-menu--scroll-container {
  grid-column-gap: 4px;
  display: flex;
  padding-bottom: 40px;

  .react-horizontal-scrolling-menu--item > article {
    height: 100%;
  }
}

.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
